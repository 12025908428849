<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Invoice</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.invoiceNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice Date</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.invoiceDate }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Currency</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.currency }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" v-if="item.totalRemainInvoice">
                <span>{{
                  formatPrice(
                    item.totalRemainInvoice === 0
                      ? item.totalAmountAfterTax
                      : item.totalRemainInvoice
                  )
                }}</span>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" v-if="!item.totalRemainInvoice">
                <span>{{ formatPrice(item.totalAmountAfterTax) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.rate"
                  @change="onInputRate"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Total Invoice (RP)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" v-if="item.totalRemainInvoiceIDR">
                <span
                  >Rp.
                  {{
                    formatPrice(
                      item.totalRemainInvoiceIDR === 0
                        ? item.totalInvoiceIDR
                        : item.totalRemainInvoiceIDR
                    )
                  }}</span
                >
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" v-if="!item.totalRemainInvoiceIDR">
                <span>Rp. {{ formatPrice(item.totalInvoiceIDR) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Invoice Paid</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.invoicePaid"
                  @change="onInputInvoicePaid"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Total Paid (RP)</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>Rp. {{ formatPrice(item.totalInvoicePaidIDR) }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="8">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPH</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="9" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="pphType"
                  item-text="accountName"
                  return-object
                  :items="pph"
                  @change="onChangePph"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  prefix="Rp."
                  background-color="white"
                  v-model.number="item.pphAmount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6">
                <v-subheader>Full Payment</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-radio-group v-model="item.fullPayment" row @change="onChangeFullPayment">
                  <v-radio label="Yes" :value="true"></v-radio>
                  <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteItem">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-invoice",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      pphType: {},
      pph: [],
    };
  },

  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index);
      this.pphType = {};
    },
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    onInputRate() {
      this.item.totalInvoicePaidIDR = this.item.invoicePaid * this.item.rate;
    },
    onInputInvoicePaid() {
      this.item.totalInvoicePaidIDR = this.item.invoicePaid * this.item.rate;
    },
    onChangePph(val) {
      this.item.pphId = val.accountId;
      this.item.pphName = val.accountName;
      this.item.pphNumber = val.accountNumber;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeFullPayment(val) {
      if (val) {
        this.item.invoicePaid = this.item.totalAmountAfterTax;
      } else {
        this.item.invoicePaid = 0;
      }
      this.onInputInvoicePaid();
    },
    async getListPph() {
      const response = await this.$store.dispatch("chartOfAccount/getPph");
      if (response.status === 200) {
        this.pph = response.data;
      }
    },
    resetStatePph() {
      this.pphType = {};
    },
  },

  mounted() {
    this.getListPph();
  },
};
</script>

<style></style>
