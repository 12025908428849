<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Vendor <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  :items="vendors"
                  item-text="supplier_name"
                  item-value="id"
                  return-object
                  v-model="vendor"
                  @change="onChangeVendor"
                  :rules="[v => !!v || 'Vendor is required']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  flat
                  dense
                  outlined
                  background-color="white"
                  item-text="invoiceNumber"
                  append-icon="mdi-magnify"
                  return-object
                  :items="listInvoice"
                  v-model="invoice"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.invoiceNumber"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-btn color="success" @click="addInvoice" class="mr-2">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pay Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="6" class="py-0">
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.paymentDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.paymentDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuStart = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Voucher Number<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="lastVoucherNumber"
                  :rules="[v => !!v || 'Voucher Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Method<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="7" class="py-0">
                <v-row>
                  <v-col>
                    <v-checkbox
                      label="Giro"
                      value="Giro"
                      v-model="methods"
                      @change="onChangeGiro"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      label="Check"
                      value="Check"
                      v-model="methods"
                      @change="onChangeCheck"
                    ></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox label="Cash" value="Cash" v-model="methods"></v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox label="Transfer" value="Transfer" v-model="methods"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Account<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="accountName"
                  return-object
                  v-model="bank"
                  :items="listBanks"
                  :filter="filterObject"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-btn color="success" @click="addBankAccount" class="mr-2">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="methods.includes('Giro')">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Giro<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="giroNumber"
                  return-object
                  v-model="giro"
                  :items="listGiro"
                >
                </v-autocomplete>
              </v-col>
              <v-btn color="success" @click="addGiro" class="mr-2">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="methods.includes('Check')">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Check<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="checkNumber"
                  return-object
                  v-model="check"
                  :items="listCheck"
                >
                </v-autocomplete>
              </v-col>
              <v-btn color="success" @click="addCheck" class="mr-2">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Pembulatan</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  prefix="Rp."
                  v-model.number="form.roundAmount"
                  :disabled="form.round === 'None'"
                  persistent-hint
                  :hint="formatPrice(form.roundAmount)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.round"
                  :items="listRound"
                  append-outer-icon="mdi-calculator"
                  @click:append-outer="calculation"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Charge<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  prefix="Rp."
                  v-model.number="form.bankCharge"
                  :rules="[v => v >= 0 || 'Bank charge musth more than equals 0']"
                  persistent-hint
                  :hint="formatPrice(form.bankCharge)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="9" lg="9" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card flat class="mb-2">
              <v-row>
                <v-col cols="12" align-self="start" class="text-right pb-0">
                  <p class="headline font-italic">Invoice <span class="red--text">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    :items="form.invoiceDtoList"
                    @click:row="onClickRow"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.no="{ item }">
                      {{
                        form.invoiceDtoList.map(x => x.referenceId).indexOf(item.referenceId) + 1
                      }}
                    </template>
                    <template v-slot:item.totalAmountAfterTax="{ item }">
                      {{ formatPrice(item.totalAmountAfterTax) }}
                    </template>
                    <template v-slot:item.rate="{ item }">
                      {{ formatPrice(item.rate) }}
                    </template>
                    <template v-slot:item.totalInvoiceIDR="{ item }">
                      {{ formatPrice(item.totalInvoiceIDR) }}
                    </template>
                    <template v-slot:item.invoicePaid="{ item }">
                      {{ formatPrice(item.invoicePaid) }}
                    </template>
                    <template v-slot:item.totalInvoicePaidIDR="{ item }">
                      {{ formatPrice(item.totalInvoicePaidIDR) }}
                    </template>
                    <template v-slot:item.fullPayment="{ item }">
                      {{ item.fullPayment ? "YES" : "NO" }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card flat class="mb-2">
              <v-row>
                <v-col cols="12" align-self="start" class="text-right pb-0">
                  <p class="headline font-italic">Bank / Cash <span class="red--text">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headerBank"
                    :items="form.accounts"
                    @click:row="onClickRowBank"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.no="{ item }">
                      {{ form.accounts.indexOf(item) + 1 }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      {{ formatPrice(item.amount) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="methods.includes('Check')">
            <v-card flat class="mb-2">
              <v-row>
                <v-col cols="12" align-self="start" class="text-right pb-0">
                  <p class="headline font-italic">Check<span class="red--text">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headerCheck"
                    :items="form.checks"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.no="{ index }">
                      {{ index + 1 }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      <v-text-field
                        type="number"
                        outlined
                        flat
                        dense
                        background-color="white"
                        prefix="Rp."
                        v-model.number="item.amount"
                        persistent-hint
                        :hint="formatPrice(item.amount)"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.action="{ index }">
                      <v-btn icon @click="deleteCheck(index)" color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="methods.includes('Giro')">
            <v-card flat class="mb-2">
              <v-row>
                <v-col cols="12" align-self="start" class="text-right pb-0">
                  <p class="headline font-italic">Giro<span class="red--text">*</span></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headerGiro"
                    :items="form.giros"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.no="{ index }">
                      {{ index + 1 }}
                    </template>
                    <template v-slot:item.amount="{ item }">
                      <v-text-field
                        type="number"
                        outlined
                        flat
                        dense
                        background-color="white"
                        prefix="Rp."
                        v-model.number="item.amount"
                        persistent-hint
                        :hint="formatPrice(item.amount)"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.action="{ index }">
                      <v-btn icon @click="deleteGiro(index)" color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title>Total Paid {{ currency !== "" ? `(${currency})` : null }}</v-card-title>
              <v-card-text class="text-right">
                <span>{{ formatPrice(total) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title>Total Paid (IDR)</v-card-title>
              <v-card-text class="text-right">
                <span>Rp. {{ formatPrice(totalIDR) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="checkJournal">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-invoice
      :dialog="dialogInvoice"
      :item="item"
      :index="index"
      @close="close"
      @save="addItem"
      @deleteItem="deleteItemInvoice"
      ref="invoice"
    ></dialog-invoice>
    <dialog-bank
      :dialog="dialogBank"
      @save="saveBank"
      @close="close"
      :item="item"
      @deleteBank="deleteBank"
      :index="index"
      type="bank"
      :totalPaid="total"
      :totalBank="totalBank"
      :round="form.round"
      :roundAmount="form.roundAmount"
    ></dialog-bank>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      :currency="currency"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import DialogInvoice from "@/components/DialogInvoice";
import DialogBank from "@/components/DialogBank";
import { mapState } from "vuex";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-purchase-payment",
  mixins: [fiscalMonthMixin],
  components: {
    DialogInvoice,
    DialogBank,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      voucherNumber: "",
      paymentDate: moment().format("yyyy-MM-DD"),
      vendorName: "",
      vendorId: null,
      methodPayment: "",
      round: "None",
      totalAmount: 0,
      bankCharge: 0,
      roundAmount: 0,
      remark: "",
      accounts: [],
      invoiceDtoList: [],
      checks: [],
      giros: [],
      status: "Request Approval",
    },
    valid: true,
    menuStart: false,
    dateStart: new Date().toISOString().substr(0, 10),
    form: {},
    dialogInvoice: false,
    dialogBank: false,
    dialogReview: false,
    item: {},
    index: 0,
    bank: {},
    check: {},
    giro: {},
    vendor: null,
    invoice: null,
    methods: [],
    listInvoice: [],
    listBanks: [],
    journals: [],
    listGiro: [],
    listCheck: [],
    accountNumber: "",
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice #",
        value: "invoiceNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Date",
        value: "invoiceDate",
        sortable: false,
        divider: true,
      },
      {
        text: "Currency",
        value: "currency",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "totalAmountAfterTax",
        sortable: false,
        divider: true,
      },
      {
        text: "Rate",
        value: "rate",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Invoice (RP)",
        value: "totalInvoiceIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Invoice Paid",
        value: "invoicePaid",
        sortable: false,
        divider: true,
      },
      {
        text: "Total Paid (RP)",
        value: "totalInvoicePaidIDR",
        sortable: false,
        divider: true,
      },
      {
        text: "Full Payment",
        value: "fullPayment",
        sortable: false,
        divider: true,
      },
      {
        text: "PPH",
        value: "pphName",
        sortable: false,
        divider: true,
      },
    ],
    headerBank: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "AccountName",
        value: "accountName",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
    ],
    headerCheck: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Check Number",
        value: "checkNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
    headerGiro: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Giro Number",
        value: "giroNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "amount",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
    currency: "",
    listRound: ["None", "UP", "DOWN"],
  }),

  computed: {
    ...mapState("purchasePayment", ["lastFormNumber", "lastVoucherNumber"]),
    ...mapState("external", ["vendors"]),
    total() {
      let total = 0;
      this.form.invoiceDtoList.forEach(x => {
        total += parseFloat(x.invoicePaid);
      });
      return parseFloat(total.toFixed(2));
    },
    totalIDR() {
      let total = 0;
      this.form.invoiceDtoList.forEach(x => {
        total += x.totalInvoicePaidIDR;
      });
      return parseFloat(total.toFixed(2));
    },
    totalBank() {
      let total = 0.0;
      this.form.accounts.forEach(x => {
        total += x.amount;
      });
      return parseFloat(total.toFixed(2));
    },
    totalCheck() {
      let total = 0.0;
      this.form.checks.forEach(x => {
        total += x.amount;
      });
      return parseFloat(total.toFixed(2));
    },
    totalGiro() {
      let total = 0.0;
      this.form.giros.forEach(x => {
        total += x.amount;
      });
      return parseFloat(total.toFixed(2));
    },
    totalPph() {
      let total = 0.0;
      if (this.form.invoiceDtoList && this.form.invoiceDtoList.length > 0) {
        this.form.invoiceDtoList.forEach(x => {
          if (x.fullPayment) {
            total += x.pphAmount;
          }
        });
      }
      return parseFloat(total.toFixed(2));
    },
    totalPaid() {
      let total = 0.0;
      if (this.form.invoiceDtoList && this.form.invoiceDtoList.length > 0) {
        this.form.invoiceDtoList.forEach(x => {
          if (x.fullPayment) {
            total += x.totalInvoicePaidIDR;
          }
        });
      }
      return parseFloat(total.toFixed(2));
    },
    setCurrency() {
      return this.form.invoiceDtoList && this.form.invoiceDtoList.length > 0
        ? this.form.invoiceDtoList[0].currency
        : "";
    },
  },

  watch: {
    setCurrency(val) {
      this.currency = val;
      this.getAP(this.vendor.id);
    },
  },

  methods: {
    checkJournal() {
      if (
        this.$refs.form.validate() &&
        this.form.invoiceDtoList.length > 0 &&
        this.methods.length > 0
      ) {
        this.journals = [
          {
            accountNumber: this.accountNumber,
            accountName: `${this.form.vendorName} ${this.currency}`,
            debit: this.total,
            credit: 0,
            debitIDR: this.totalIDR,
            creditIDR: 0,
          },
        ];
        if (this.form.bankCharge > 0) {
          this.journals.unshift({
            accountNumber: "916.00001",
            accountName: `BIAYA ADMINISTRASI BANK`,
            debit: this.form.bankCharge,
            credit: 0,
            debitIDR: this.form.bankCharge,
            creditIDR: 0,
          });
        }
        if (this.methods.includes("Transfer") || this.methods.includes("Cash")) {
          if (this.form.accounts.length > 0) {
            this.form.accounts.forEach(x =>
              this.journals.push({
                accountNumber: x.accountNumber,
                accountName: `${x.accountName}`,
                debit: 0,
                credit: x.amount,
                debitIDR: 0,
                creditIDR: x.amount,
              })
            );
            if (this.form.round === "UP") {
              this.journals.unshift({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: this.form.roundAmount,
                credit: 0,
                debitIDR: this.form.roundAmount,
                creditIDR: 0,
              });
            } else if (this.form.round === "DOWN") {
              this.journals.push({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: 0,
                credit: this.form.roundAmount,
                debitIDR: 0,
                creditIDR: this.form.roundAmount,
              });
            }
            let map = this.form.invoiceDtoList.map(x => {
              return {
                accountNumber: x.pphNumber,
                accountName: x.pphName,
              };
            });
            let array = map.filter(
              (v, i) =>
                map.findIndex(
                  x => x.accountName === v.accountName && x.accountNumber === v.accountNumber
                ) === i
            );
            array.forEach(y => {
              let total = 0;
              let totalIDR = 0;
              this.form.invoiceDtoList.forEach(x => {
                if (x.pphName === y.accountName) {
                  total += x.pphAmount;
                  totalIDR += x.pphAmount * x.rate;
                }
              });
              if (total > 0) {
                this.journals.push({
                  accountNumber: y.accountNumber,
                  accountName: `${y.accountName}`,
                  debit: 0,
                  credit: total,
                  debitIDR: 0,
                  creditIDR: totalIDR,
                });
              }
            });
            this.journals.forEach(x => {
              this.$store
                .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
                .then(response => (x.pointers = response.data));
            });
            this.dialogReview = true;
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Accounts is required",
            });
          }
        } else if (this.methods.includes("Check")) {
          if (this.form.checks.length > 0) {
            this.journals.push({
              accountNumber: "112.99999",
              accountName: "BANK IN-TRANSIT",
              debit: 0,
              credit: this.totalCheck,
              debitIDR: 0,
              creditIDR: this.totalCheck,
            });
            if (this.form.round === "UP") {
              this.journals.unshift({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: this.form.roundAmount,
                credit: 0,
                debitIDR: this.form.roundAmount,
                creditIDR: 0,
              });
            } else if (this.form.round === "DOWN") {
              this.journals.push({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: 0,
                credit: this.form.roundAmount,
                debitIDR: 0,
                creditIDR: this.form.roundAmount,
              });
            }
            let map = this.form.invoiceDtoList.map(x => {
              return {
                accountNumber: x.pphNumber,
                accountName: x.pphName,
              };
            });
            let array = map.filter(
              (v, i) =>
                map.findIndex(
                  x => x.accountName === v.accountName && x.accountNumber === v.accountNumber
                ) === i
            );
            array.forEach(y => {
              let total = 0;
              let totalIDR = 0;
              this.form.invoiceDtoList.forEach(x => {
                if (x.pphName === y.accountName) {
                  total += x.pphAmount;
                  totalIDR += x.pphAmount * x.rate;
                }
              });
              if (total > 0) {
                this.journals.push({
                  accountNumber: y.accountNumber,
                  accountName: `${y.accountName}`,
                  debit: 0,
                  credit: total,
                  debitIDR: 0,
                  creditIDR: totalIDR,
                });
              }
            });
            this.journals.forEach(x => {
              this.$store
                .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
                .then(response => (x.pointers = response.data));
            });
            this.dialogReview = true;
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Check is required",
            });
          }
        } else if (this.methods.includes("Giro")) {
          if (this.form.giros.length > 0) {
            this.journals.push({
              accountNumber: "112.99999",
              accountName: "BANK IN-TRANSIT",
              debit: 0,
              credit: this.totalGiro,
              debitIDR: 0,
              creditIDR: this.totalGiro,
            });
            if (this.form.round === "UP") {
              this.journals.unshift({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: this.form.roundAmount,
                credit: 0,
                debitIDR: this.form.roundAmount,
                creditIDR: 0,
              });
            } else if (this.form.round === "DOWN") {
              this.journals.push({
                accountNumber: "691.00000",
                accountName: "SELISIH KURS",
                debit: 0,
                credit: this.form.roundAmount,
                debitIDR: 0,
                creditIDR: this.form.roundAmount,
              });
            }
            let map = this.form.invoiceDtoList.map(x => {
              return {
                accountNumber: x.pphNumber,
                accountName: x.pphName,
              };
            });
            let array = map.filter(
              (v, i) =>
                map.findIndex(
                  x => x.accountName === v.accountName && x.accountNumber === v.accountNumber
                ) === i
            );
            array.forEach(y => {
              let total = 0;
              let totalIDR = 0;
              this.form.invoiceDtoList.forEach(x => {
                if (x.pphName === y.accountName) {
                  total += x.pphAmount;
                  totalIDR += x.pphAmount * x.rate;
                }
              });
              if (total > 0) {
                this.journals.push({
                  accountNumber: y.accountNumber,
                  accountName: `${y.accountName}`,
                  debit: 0,
                  credit: total,
                  debitIDR: 0,
                  creditIDR: totalIDR,
                });
              }
            });
            this.journals.forEach(x => {
              this.$store
                .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
                .then(response => (x.pointers = response.data));
            });
            this.dialogReview = true;
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Giro is required",
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Methods and Invoices is required",
        });
      }
    },
    async submit() {
      if (
        this.$refs.form.validate() &&
        this.form.invoiceDtoList.length > 0 &&
        this.methods.length > 0
      ) {
        this.form.totalAmount = this.total;
        this.form.formNumber = this.lastFormNumber;
        this.form.voucherNumber = this.lastVoucherNumber;
        this.form.methodPayment = this.methods.toString();

        let totalDebit = parseFloat((this.totalIDR + this.form.bankCharge).toFixed(2));
        let totalCredit;
        if (this.methods.includes("Transfer") || this.methods.includes("Cash")) {
          totalCredit = this.totalBank;
        } else if (this.methods.includes("Giro")) {
          totalCredit = this.totalGiro;
        } else {
          totalCredit = this.totalCheck;
        }

        if (this.form.round === "UP") {
          totalDebit += this.form.roundAmount;
        } else if (this.form.round === "DOWN") {
          totalCredit += this.form.roundAmount;
        }

        if (parseFloat(totalDebit.toFixed(2)) - parseFloat(totalCredit.toFixed(2)) === 0) {
          this.$store.commit("SET_LOADER", true);
          await this.$store
            .dispatch("purchasePayment/create", this.form)
            .then(() => this.$store.commit("SET_LOADER", false))
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Methods, Invoices, and Accounts is required",
        });
      }
    },
    getAP(id) {
      this.$store
        .dispatch("chartOfAccount/getAP", { id: id, code: this.currency })
        .then(response => (this.accountNumber = response.data));
    },
    onChangeVendor(val) {
      this.form.vendorId = val.id;
      this.form.vendorName = val.supplier_name;
      this.getListInvoice(val.id);
    },
    getListInvoice(vendorId) {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("purchasePayment/getListInvoice", vendorId)
        .then(response => {
          this.listInvoice = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    addInvoice() {
      if (this.form.invoiceDtoList.indexOf(this.invoice) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Invoice sudah digunakan",
        });
      } else {
        this.item = Object.assign({}, this.invoice);
        this.index = this.form.invoiceDtoList.map(x => x).indexOf(this.invoice);
        this.$refs.invoice.resetStatePph();
        this.dialogInvoice = true;
      }
    },
    onInputPercentagePph() {
      this.form.pphAmount = (this.form.percentagePph / 100) * this.form.totalAmount;
    },
    addBankAccount() {
      if (this.form.accounts.indexOf(this.bank) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Account sudah digunakan",
        });
      } else {
        this.item = Object.assign({}, this.bank);
        this.index = this.form.accounts.indexOf(this.bank);
        this.dialogBank = true;
      }
    },
    addCheck() {
      if (this.form.checks.findIndex(x => x.checkNumber === this.check.checkNumber) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Check sudah digunakan",
        });
      } else {
        this.form.checks.push(this.check);
      }
    },
    addGiro() {
      if (this.form.giros.findIndex(x => x.giroNumber === this.giro.giroNumber) > -1) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Giro sudah digunakan",
        });
      } else {
        this.form.giros.push(this.giro);
      }
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.invoiceDtoList.findIndex(x => x === item);
      this.dialogInvoice = true;
    },
    onClickRowBank(item) {
      this.item = item;
      this.index = this.form.accounts.findIndex(x => x === item);
      this.dialogBank = true;
    },
    saveInvoice(data) {
      if (data.index !== -1) {
        this.form.invoiceDtoList[data.index] = data.item;
      }
    },
    addItem() {
      if (this.index !== -1) {
        this.form.invoiceDtoList[this.index] = this.item;
      } else {
        this.form.invoiceDtoList.push(this.item);
      }
    },
    deleteItemInvoice(index) {
      if (index !== -1) {
        this.form.invoiceDtoList.splice(index, 1);
        this.dialogInvoice = false;
      }
    },
    deleteBank(index) {
      if (index > -1) {
        this.form.accounts.splice(index, 1);
      }
    },
    deleteCheck(index) {
      if (index > -1) {
        this.form.checks.splice(index, 1);
      }
    },
    deleteGiro(index) {
      if (index > -1) {
        this.form.giros.splice(index, 1);
      }
    },
    onChangeGiro(val) {
      if (val === null) {
        this.form.giros = [];
        this.giro = {};
      }
    },
    onChangeCheck(val) {
      if (val === null) {
        this.form.checks = [];
        this.check = {};
      }
    },
    saveBank(item) {
      if (this.index !== -1) {
        this.form.accounts[this.index] = this.item;
      } else {
        this.form.accounts.push(item);
      }
    },
    close() {
      this.dialogInvoice = false;
      this.dialogBank = false;
      this.dialogReview = false;
    },
    clear() {
      this.vendor = null;
      this.bank = null;
      this.invoice = null;
      this.methods = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getBanks() {
      this.$store.dispatch("chartOfAccount/getBanks").then(response => {
        this.listBanks = response.data;
      });
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    calculation() {
      if (this.form.invoiceDtoList.length > 0) {
        if (this.totalPaid > 0) {
          let total = parseFloat((this.totalPaid - (this.totalBank + this.totalPph)).toFixed(2));
          if (total > 0) {
            this.form.round = "DOWN";
            this.form.roundAmount = total;
          } else if (total < 0) {
            this.form.round = "UP";
            this.form.roundAmount = Math.abs(total);
          } else {
            this.form.round = "None";
            this.form.roundAmount = 0;
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Invoice is Required",
        });
      }
    },
    async getListGiro() {
      await this.$store
        .dispatch("giro/getListGiro")
        .then(response => (this.listGiro = response.data));
    },
    async getListCheck() {
      await this.$store
        .dispatch("check/getListCheck")
        .then(response => (this.listCheck = response.data));
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getBanks(),
        this.$store.dispatch("purchasePayment/getLastFormNumber"),
        this.$store.dispatch("purchasePayment/getLastVoucherNumber"),
        this.$store.dispatch("external/getVendors"),
        this.getListGiro(),
        this.getListCheck(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  created() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
