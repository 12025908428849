<template>
  <v-dialog v-model="dialog" max-width="900" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Bank / Cash</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Account Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.accountNumber }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Account Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6">
                <span>{{ item.accountName }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="type === 'other'">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Type</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select dense outlined flat :items="listType" v-model="item.nature"></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                <v-subheader>Amount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                <v-text-field
                  dense
                  outlined
                  flat
                  type="number"
                  v-model.number="item.amount"
                  append-outer-icon="mdi-calculator"
                  @click:append-outer="calculation"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="type === 'other'">
            <v-row>
              <v-col cols="4" sm="4" md="6" lg="6" class="py-0">
                <v-subheader>Reference</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea outlined flat rows="3" v-model="item.reference"></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="red" text @click="deleteBank" class="mr-2">
          Delete
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-bank",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    type: String,
    totalPaid: {
      type: Number,
      default: 0.0,
    },
    totalBank: {
      type: Number,
      default: 0.0,
    },
    roundAmount: {
      type: Number,
      default: 0.0,
    },
    round: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      listType: ["Debit", "Credit"],
    };
  },
  methods: {
    deleteBank() {
      this.$emit("deleteBank", this.index);
      this.$emit("close");
    },
    save() {
      this.$emit("save", this.item);
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    calculation() {
      if (this.round === "None") {
        this.item.amount = this.totalPaid - this.totalBank;
      } else if (this.round === "UP") {
        this.item.amount = this.totalPaid + this.roundAmount - this.totalBank;
      } else {
        this.item.amount = this.totalPaid - this.roundAmount - this.totalBank;
      }
    },
  },
};
</script>

<style></style>
